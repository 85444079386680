<template>
  <Dice :value="course.students" :name="`course_dice_${course.id}`" />
</template>

<script>
import { defineComponent } from "vue";
import Dice from "@/components/Dice";
export default defineComponent({
  name: "CourseDice",
  components: { Dice },
  props: ["course"],
  data() {
    return {};
  },
  methods: {},
  created() {},
});
</script>

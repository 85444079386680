<template>
  <div>
    <v-card class="mb-4">
      <v-system-bar
        >Personen<v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-help </v-icon>
          </template>
          <span>Einzelnen Personen können durch Klick deaktiviert werden</span>
        </v-tooltip>
      </v-system-bar>
      <v-chip-group column>
        <PersonChip
          hideExt
          v-for="student in candidates"
          :key="student.id"
          :value="student"
          :disabled="student.disabled"
          :class="[student.active ? 'active' : '']"
          @click="toggle(student)"
        />
      </v-chip-group>
      <v-divider></v-divider>
      <v-card-actions
        ><v-btn
          text
          @click="resetChoice()"
          :disabled="
            candidates.filter((el) => !el.disabled && !el.active).length ==
            value.length
          "
          >zurücksetzen</v-btn
        ><v-spacer></v-spacer>
        <v-btn text @click="choice()" class="mr-2" color="primary"
          >zufällig wählen</v-btn
        ></v-card-actions
      >
    </v-card>

    <v-card class="mb-4">
      <v-system-bar>neue Gruppen bilden</v-system-bar>
      <v-card-actions>
        <v-btn text @click="reset()" class="mr-2" :disabled="!hasAnything"
          >zurücksetzen</v-btn
        >
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex">
              <v-btn text @click="toggleMixed">
                <v-simple-checkbox :value="mixed"></v-simple-checkbox>
                mixed</v-btn
              >
            </div>
          </template>
          <span>Geschlechter werden wenn möglich gleichmässig verteilt</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    tile
                    text
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    color="primary"
                  >
                    Gruppen bilden
                  </v-btn>
                </template>
                <v-list subheader>
                  <v-list-item
                    v-for="index in 5"
                    :key="'group' + index"
                    @click="group(index + 1)"
                  >
                    <v-list-item-title
                      >{{ index + 1 }}er-Gruppen</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span
            >Die Klasse wird in gleichgrosse Gruppen unterteilt. Es kann eine
            kleinere Gruppe übrigbleiben.</span
          >
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    tile
                    text
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    color="primary"
                  >
                    Klasse teilen
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="index in 5"
                    :key="'divide' + index"
                    @click="divide(index + 1)"
                  >
                    <v-list-item-title>{{ index + 1 }} Teile</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Die Klasse wird in ähnlich-grosse Teile unterteilt.</span>
        </v-tooltip>

        <v-btn tile text @click="shuffle()" color="primary"
          >zufällige reihenfolge</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="shuffeled.length > 0">
      <v-system-bar
        >Zufällige Reihenfolge<v-spacer></v-spacer
        ><v-btn @click="reset" icon
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            right
            absolute
            text
            @click="copyShuffle()"
            ><v-icon>mdi-content-copy</v-icon></v-btn
          >
        </template>
        <span>Zufällige Reihenfolge in Zwischenablage kopieren</span>
      </v-tooltip>
      <v-card-text
        >Die Personen wurden zufällig
        {{ mixed ? "nach Geschlecht gemischt" : "" }} nummeriert:</v-card-text
      >
      <v-divider></v-divider>
      <v-list subheader>
        <transition-group
          name="flip-list"
          tag="div"
          class="mt-4 d-flex justify-start flex-wrap"
        >
          <PersonItemBasic
            v-for="(person, index) in shuffeled"
            :key="'shuffeled' + person.id"
            :value="person"
          >
            <v-chip>{{ index + 1 }}</v-chip>
          </PersonItemBasic>
        </transition-group>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="reset()" class="mr-2" :disabled="!hasAnything"
          >zurücksetzen</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="groups.length > 0" class="mb-4">
      <v-system-bar
        >{{ groups.length }} erstellte
        {{ mixed ? " Geschlechter-gemischte " : "" }}Gruppen<v-spacer></v-spacer
        ><v-btn @click="reset" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-system-bar>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-switch
                inset
                v-model="sorted"
                label="sortiert"
                hide-details
                class="mt-0"
                :disabled="shuffeled.length > 0"
              ></v-switch>
            </div>
          </template>
          <span>Personen in einzelnen Gruppen nach Name sortieren</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="copyGroups()"
              ><v-icon>mdi-content-copy</v-icon></v-btn
            >
          </template>
          <span>Gruppeneinteilung in Zwischenablage kopieren</span>
        </v-tooltip>
      </v-toolbar>

      <div class="d-flex align-start flex-wrap">
        <v-card
          class="mb-4 mx-2"
          width="330"
          flat
          outlined
          v-for="(group, index) in groups"
          :key="'group' + index"
        >
          <v-system-bar>
            Gruppe {{ index + 1 }} <v-spacer />{{
              group.length
            }}
            Personen</v-system-bar
          >
          <v-list>
            <transition-group
              name="flip-list"
              tag="div"
              class="d-flex justify-start flex-wrap"
            >
              <PersonItemBasic
                v-for="(person, index) in needsSort(group)"
                :key="'group' + person.id"
                :value="person"
              >
                <v-chip>{{ index + 1 }}</v-chip>
              </PersonItemBasic>
            </transition-group>
          </v-list>
          <template
            v-if="
              index == groups.length - 1 &&
              groups[groups.length - 1].length < groupSize
            "
          >
            <v-divider></v-divider>
            <v-card-actions
              ><v-btn @click="distributeLast()" block text
                >Letzte Gruppe verteilen</v-btn
              ></v-card-actions
            >
          </template>
        </v-card>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="reset()" class="mr-2" :disabled="!hasAnything"
          >zurücksetzen</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonChip from "common/components/PersonChip.vue";

import { copyToClipboard } from "common/utils/helper.js";
import { personName, sortPeople } from "common/utils/people";

export default defineComponent({
  name: "Dice",
  components: { PersonItemBasic, PersonChip },
  props: {
    name: {
      type: String,
      default: "Würfeln",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      candidates: [],
      groups: [],
      groupSize: 0,
      mixed: false,
      shuffeled: [],
      sorted: false,
    };
  },
  computed: {
    hasAnything() {
      return this.shuffeled.length > 0 || this.groups.length > 0;
    },
  },
  watch: {
    value() {
      this.prepare();
    },
    groups() {
      if (this.groups.length == 0) {
        localStorage.removeItem(this.name + "_groups");
      } else {
        localStorage.setItem(
          this.name + "_groups",
          JSON.stringify(this.groups)
        );
      }
    },
    shuffeled() {
      if (this.shuffeled.length == 0) {
        localStorage.removeItem(this.name + "_shuffeled");
      } else {
        localStorage.setItem(
          this.name + "_shuffeled",
          JSON.stringify(this.shuffeled)
        );
      }
    },
    sorted() {
      localStorage.setItem(this.name + "_sorted", JSON.stringify(this.sorted));
    },
  },
  methods: {
    copyToClipboard,
    personName,
    async toggleMixed() {
      if (await this.reset()) {
        this.mixed = !this.mixed;
        localStorage.setItem(this.name + "_mixed", JSON.stringify(this.mixed));
      }
    },
    async resetChoice() {
      for (const person of this.value) {
        localStorage.removeItem(this.name + "_student_" + person.id);
      }
      this.prepare();
    },
    async reset() {
      if (!this.hasAnything) return true;
      if (
        await this.$root.confirm({
          message: "Die bereits erstellten Gruppen gehen verloren.",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.groups = [];
        this.shuffeled = [];
        return true;
      } else {
        return false;
      }
    },
    async prepare() {
      if (this.candidates.length == 0) {
        this.candidates = [];
        for (const person of this.value) {
          let active = false;
          let disabled = false;
          if (localStorage.getItem(this.name + "_student_" + person.id)) {
            ({ active, disabled } = JSON.parse(
              localStorage.getItem(this.name + "_student_" + person.id)
            ));
          }
          this.candidates.push({
            ...person,
            disabled: disabled,
            active: active,
          });
        }
      } else if (
        await this.$root.confirm({
          message:
            "Bereits gewählte (oder manuell deaktivierte) Personen werden wieder aktiv.",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.candidates = [];
        for (const person of this.value) {
          this.candidates.push({ ...person, disabled: false, active: false });
        }
      }

      if (localStorage.getItem(this.name + "_groups")) {
        this.groups = JSON.parse(localStorage.getItem(this.name + "_groups"));
      } else {
        this.groups = [];
      }
      if (localStorage.getItem(this.name + "_shuffeled")) {
        this.shuffeled = JSON.parse(
          localStorage.getItem(this.name + "_shuffeled")
        );
      } else {
        this.shuffeled = [];
      }
      if (localStorage.getItem(this.name + "_sorted")) {
        this.sorted = JSON.parse(localStorage.getItem(this.name + "_sorted"));
      } else {
        this.sorted = false;
      }
      if (localStorage.getItem(this.name + "_mixed")) {
        this.mixed = JSON.parse(localStorage.getItem(this.name + "_mixed"));
      } else {
        this.mixed = false;
      }
    },
    toggle(student) {
      student.disabled = !student.disabled;
      localStorage.setItem(
        this.name + "_student_" + student.id,
        JSON.stringify({ active: student.active, disabled: student.disabled })
      );
    },
    distributeLast() {
      var remaining = this.groups.pop();
      var currentIndex = remaining.length;
      var groupIndex = 0;
      while (currentIndex > 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        var person = remaining[randomIndex];
        this.groups[groupIndex].push(person);
        currentIndex--;
        groupIndex = (groupIndex + 1) % this.groups.length;
      }
    },
    async divide(size) {
      if (this.mixed) {
        return await this.divideMixed(size);
      }
      if (await this.reset()) {
        this.groupSize = 0;
        for (let i = 0; i < size; i++) {
          this.groups.push([]);
        }
        var remaining = this.candidates.filter((el) => !el.disabled);
        var currentIndex = remaining.length;
        var groupIndex = 0;
        while (currentIndex > 0) {
          var randomIndex = Math.floor(Math.random() * currentIndex);
          var person = remaining[randomIndex];
          this.groups[groupIndex].push(person);
          remaining = remaining.filter((el) => el.id != person.id);
          currentIndex = currentIndex - 1;
          groupIndex = (groupIndex + 1) % size;
        }
      }
    },
    async divideMixed(size) {
      if (await this.reset()) {
        var remaining = await this.doShuffleGenderSort();

        this.groupSize = 0;
        for (let i = 0; i < size; i++) {
          this.groups.push([]);
        }

        var currentIndex = remaining.length;
        var groupIndex = 0;
        while (currentIndex > 0) {
          this.groups[groupIndex].push(remaining.pop());
          currentIndex = currentIndex - 1;
          groupIndex = (groupIndex + 1) % size;
        }
      }
    },
    async group(groupSize) {
      if (this.mixed) {
        return await this.groupMixed(groupSize);
      }
      if (await this.reset()) {
        this.groupSize = groupSize;
        var remaining = this.candidates.filter((el) => !el.disabled);
        const size = Math.ceil(remaining.length / groupSize);
        for (let i = 0; i < size; i++) {
          this.groups.push([]);
        }
        var currentIndex = remaining.length;
        var groupIndex = 0;
        while (currentIndex > 0) {
          var randomIndex = Math.floor(Math.random() * currentIndex);
          var person = remaining[randomIndex];
          this.groups[groupIndex].push(person);
          if (this.groups[groupIndex].length == groupSize) {
            groupIndex++;
          }
          remaining = remaining.filter((el) => el.id != person.id);
          currentIndex--;
        }
      }
    },
    async groupMixed(groupSize) {
      if (await this.reset()) {
        this.groupSize = groupSize;

        var remaining = await this.doShuffleGenderSort();

        const size = Math.ceil(remaining.length / groupSize);
        for (let i = 0; i < size; i++) {
          this.groups.push([]);
        }

        var ratio = Math.max(
          1,
          Math.round(
            remaining.filter((el) => el.gender.id == 1).length /
              this.groups.length
          )
        );

        var currentIndex = remaining.length;
        var groupIndex = 0;
        var index = 0;
        while (currentIndex > 0) {
          if (index < ratio) {
            this.groups[groupIndex].push(remaining.pop());
          } else {
            this.groups[groupIndex].push(remaining.shift());
          }
          index++;
          if (this.groups[groupIndex].length == groupSize) {
            groupIndex++;
            index = 0;
          }
          currentIndex--;
        }
      }
    },
    choice() {
      for (const person of this.candidates.filter((el) => el.active)) {
        person.active = false;
        person.disabled = true;
        localStorage.setItem(
          this.name + "_student_" + person.id,
          JSON.stringify({ active: person.active, disabled: person.disabled })
        );
      }
      var remaining = this.candidates.filter((el) => !el.disabled && !el.done);
      var currentIndex = remaining.length;
      if (currentIndex == 0) {
        return;
      }

      var randomIndex = Math.floor(Math.random() * currentIndex);
      var person = remaining[randomIndex];
      person.active = true;
      localStorage.setItem(
        this.name + "_student_" + person.id,
        JSON.stringify({ active: person.active, disabled: person.disabled })
      );
    },
    async shuffle() {
      if (this.mixed) {
        return await this.shuffleMixed();
      }
      if (await this.reset()) {
        var remaining = this.candidates.filter((el) => !el.disabled);
        var currentIndex = remaining.length;
        while (currentIndex > 0) {
          var randomIndex = Math.floor(Math.random() * currentIndex);
          var person = remaining[randomIndex];
          this.shuffeled.push(person);
          remaining = remaining.filter((el) => el.id != person.id);
          currentIndex = currentIndex - 1;
        }
      }
    },
    async shuffleMixed() {
      if (await this.reset()) {
        var remaining = await this.doShuffleGenderSort();
        var half_length = Math.ceil(remaining.length / 2);

        var leftSide = remaining.slice(0, half_length);
        var rightSide = remaining.slice(half_length, remaining.length);

        var currentIndexLeft = leftSide.length;
        var currentIndexRight = rightSide.length;

        while (currentIndexLeft > 0 || currentIndexRight > 0) {
          if (currentIndexLeft > 0) {
            var randomIndexLeft = Math.floor(Math.random() * currentIndexLeft);
            var personLeft = leftSide[randomIndexLeft];
            this.shuffeled.push(personLeft);
            leftSide = leftSide.filter((el) => el.id != personLeft.id);
            currentIndexLeft = currentIndexLeft - 1;
          }
          if (currentIndexRight > 0) {
            var randomIndexRight = Math.floor(
              Math.random() * currentIndexRight
            );
            var personRight = rightSide[randomIndexRight];
            this.shuffeled.push(personRight);
            rightSide = rightSide.filter((el) => el.id != personRight.id);
            currentIndexRight = currentIndexRight - 1;
          }
        }
      }
    },
    async doShuffleGenderSort() {
      var remaining = this.candidates.filter((el) => !el.disabled);
      const result = [];
      var currentIndex = remaining.length;
      while (currentIndex > 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        var person = remaining[randomIndex];
        if (person.gender.id == 1) {
          result.push(person);
        } else {
          result.unshift(person);
        }
        remaining = remaining.filter((el) => el.id != person.id);
        currentIndex = currentIndex - 1;
      }
      return result;
    },
    needsSort(group) {
      if (this.sorted) {
        return sortPeople(group);
      }
      return group;
    },
    copyGroups() {
      let result = "";
      let index = 1;
      for (const group of this.groups) {
        result += "Gruppe " + index + "\n";
        result += this.groupToText(group) + "\n";
        index++;
      }
      this.copyToClipboard(result);
    },
    copyShuffle() {
      this.copyToClipboard(this.groupToText(this.shuffeled));
    },
    groupToText(group) {
      let result = "";
      for (const person of this.needsSort(group)) {
        result += this.personName(person) + "\n";
      }
      return result;
    },
  },
  mounted() {
    this.prepare();
  },
});
</script>
<style scoped>
:deep .large .v-chip .v-avatar {
  height: 44px !important;
  min-width: 44px !important;
  width: 44px !important;
}

:deep .v-chip.active.v-chip--outlined.v-chip.v-chip {
  background: lightblue !important;
}

.flip-list-move {
  transition: transform 1s;
}
</style>
